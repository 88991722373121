import { createContext, useState } from "react";
export interface IProps{
    confirmation:boolean|null,
    setConfirmation:(newValue:any)=>void
}
const iUserConfirmationState={
    confirmation:null,
    setConfirmation:()=>undefined
}

const UserContext=createContext<IProps>(iUserConfirmationState);

export default UserContext;