import { Row,Container as bootstrapContainer } from "react-bootstrap";
import styled from "styled-components";
import LoopIcon from '@mui/icons-material/Loop';
import seta from '../../assets/img/seta.webp';
import { FormControlLabel } from "@mui/material";
import { FaQuestionCircle as FaQuestionMark } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";

export const Header = styled.header`
    background-color: #0a1d3e;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
`;
export const HeaderRow = styled(Row)`
    height: 100px;
    color: white;
    h6{
        margin: 0px;
    }
    a{
        margin: 0px;
        text-decoration: none;
        color: white;
        font-size: 0.91rem;
    }
    svg{
        fill:white;
        
    }
    h1{
        font-family: sans-serif;
        font-weight: bold;
        font-size: 1.3rem;
        margin: 0;
    }
    
`;
export const MainContainer = styled(bootstrapContainer)`
    h4{
        margin: 0;
        padding: 0;
        font-size: 1.8rem;
        color: #1D2393;
        
    }
`;
export const BoardAvisosDominio = styled.section`
    background-color: whitesmoke;
    border: 2px solid #dce1e5;
    
    p{
        font-size: 0.75rem;
        margin: 0;
    }
    input{
        padding-left: 20px;
    }
    textarea{
        padding-left: 20px;
    }
    
`;

export const InputBox = styled.div`
    position: relative;
    border: 2px solid #dce1e5;
    height: 40px;
    padding: 0;
    font-size: 1.5rem;
    padding-left: 10px;
    input{
        width: 66.5%;
        border: none;
        outline: none;
        margin: 0;
    }
    select{
        position: absolute;
        right: 15px;
        border: none;
        outline: none;
        background-color: white;
        cursor: pointer;
    }
    &.green{
        
        border-color: green;
    }
    &.red{
        border-color: red;
    }
`;
export const ActionButton = styled.button`
    width: 100%;
    height: 100%;
    border: none;
    background-color: #2c4593;
    color: white;
    cursor: pointer;
    font-family: Arial;
    font-size: 1.2rem;
    height: 40px;
    @media (max-width: 986px) {
        font-size: 0.9rem;
    }
    &:disabled{
        background-color: #c3c3c3;
        cursor: default;
    }
`;

export const NextStep = styled.button`
    height: 53px;
    background: green;
    width: 180px;
    color: white;
    font-size: 20px;
    border: none;
    &:disabled{
        background-color: #c3c3c3;
        cursor: default;
    }
    @media only screen and (max-width: 575px) {
        width: 100%;
    }
    
`;
export const Select = styled.select`
    padding-right: 30px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: 0.9em auto, 100%;
`;


export const Passo=styled.span`
    color: #212529;
    font-family: arial;
    font-weight: bold;
    font-size: 52px;
    font-style: italic;
    text-align: center;
    padding-right: 20px;
`;
export const CategorySelect=styled.select`
    width: 100%;
    height: 2.3rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: 0.9em auto, 100%;
    padding-left: 20px;
    background-color: white;
    cursor: pointer;
    border: 1px solid rgb(198, 198, 198);
`;
export const TextInput=styled.input`
    width: 100%;
    height: 2.3rem;
    border: 1px solid rgb(198, 198, 198);
`;
export const TextArea=styled.textarea`
    width: 100%;
    
    border: 1px solid rgb(198, 198, 198);
`;
export const PassoSeta=styled.div`
    background-image: url(${seta});
    width: 76px;
    height: 44px;
`;
export const PassoBox=styled.div`
    display: flex;
    align-items: center;
    padding: 0;
    h4{
        color:#212529;
        margin: 0;
    }
`;
export const Asteristico=styled.span`
    color:red;
    
`;
export const FormControlLabelStyled=styled(FormControlLabel)`
    &:hover{
        background-color: #fbfcd4;
    }
    
    
    p{
        
        margin: 0;
        padding: 0;
        
        span{
        color: #1F238F;
        }
    }
    
`;
export const FaQuestionCircle=styled(FaQuestionMark)`
    width: 23px;
    height: 23px;
`;

export const ReCAPTCHAStyled = styled(ReCAPTCHA)`
    @media (min-width: 320px) {
        transform:scale(0.65);
        transform-origin:0 0;
    }
    @media (min-width: 375px) {
        transform:scale(0.85);
        transform-origin:0 0;
    }
    @media (min-width: 425px) {
        transform:scale(1.0);
        transform-origin:0 0;
    }
    
    @media (min-width: 768px) {
        transform:scale(0.87);
        transform-origin:0 0;
    }
    
`;