const Hostnetlogo=()=>{
    return(
        <svg xmlns="https://www.w3.org/2000/svg" height="47px"  viewBox="0 0 2480 590.476" >
					 <g id="svg-hostnet-path">
					  <metadata id="CorelCorpID_0Corel-Layer"></metadata>
					  <polygon className="fil0" points="985.339,197.048 1018.64,150.941 1018.64,150.946 1018.65,150.941 1036.01,150.941 1036.01,197.048 1018.64,197.048 "></polygon>
					  <path className="fil1" d="M355.434 404.528c0,0 -78.5522,-49.5221 -37.5685,-140.028 21.413,-47.2865 73.8261,-38.6337 119,-19.5885 0,0 15.2886,-113.382 138.07,-110.398 122.781,2.98427 141.877,134.525 140.814,147.917 0,0 50.8542,-5.81619 77.4492,24.7114l51.6974 0c-11.5332,-26.5502 -38.0491,-56.975 -99.0051,-60.5049 -11.3903,-182.102 -259.092,-241.397 -332.533,-61.817 -132.59,-24.4115 -174.058,174.202 -57.9245,219.708zm452.356 -64.9642c0.714476,4.3589 1.13135,9.04255 1.19749,14.0805 0.700305,53.4983 -57.4545,70.0376 -99.2118,69.2404l-58.1017 36.0616c79.8631,0 198.339,9.09333 202.077,-111.271 0,0 0.299962,-11.1399 -3.76606,-26.0353l-42.1883 17.9245 -0.00708571 -0.00118095z"></path>
					  <path className="fil1" d="M278.589 306.338c0,0 -1.70766,135.759 160.52,134.051 0,0 74.2831,1.70766 211.749,-70.0139 137.466,-71.7216 226.265,-56.3527 226.265,-56.3527 0,0 -70.0139,20.4919 -214.311,118.682 -144.297,98.1903 -255.295,94.775 -317.624,57.2065 -62.3295,-37.5685 -85.3829,-104.167 -66.5986,-183.573z"></path>
					  <circle className="fil1" cx="916.847" cy="311.012" r="30.269"></circle>
					  <path className="fil1" d="M1291.92 230.404c65.2925,0 91.9926,45.1679 91.9926,100.865 0,55.6973 -26.7002,100.865 -91.9926,100.865 -65.2925,0 -91.9926,-45.1679 -91.9926,-100.865 0,-55.6973 26.7002,-100.865 91.9926,-100.865zm30.3469 100.865c0,-28.5212 -8.64221,-45.1714 -30.3469,-45.1714 -21.7047,0 -30.3469,16.6502 -30.3469,45.1714 0,28.5212 8.64221,45.1714 30.3469,45.1714 21.7047,0 30.3469,-16.6502 30.3469,-45.1714z"></path>
					  <path className="fil1" d="M1187.99 303.918l0 -9.74404c0,-32.5222 -26.5242,-59.0464 -59.0464,-59.0476l-25.5038 0c-23.1337,0 -27.1182,-15.9003 -27.1182,-45.1714l-0.121638 -39.0139 -57.5537 0 0 281.193 57.5537 0 0 -104.514c0,-29.2711 3.98453,-45.1714 27.1182,-45.1714 23.1337,0 27.1182,15.9003 27.1182,45.1714l0 104.514 57.5537 0 0 -128.216z"></path>
					  <path className="fil1" d="M1515.05 328.787l6.90621 5.19265c13.4747,10.54 22.999,25.2086 22.1972,40.8338 0.527886,35.0849 -25.7176,57.2431 -60.9383,57.3211l-84.4038 0 0 -54.9237 71.0674 0c1.92259,-0.00590476 3.35863,-1.21402 3.33029,-3.13897 0.0401524,-0.849105 -0.477105,-1.64507 -1.21874,-2.22728l-52.5087 -35.5419 -6.90621 -5.19265c-13.4747,-10.54 -22.999,-25.2086 -22.1972,-40.8338 -0.527886,-35.0849 25.7176,-57.2431 60.9383,-57.3211l84.4038 0 0 54.9237 -71.0674 0c-1.92259,0.00590476 -3.35863,1.21402 -3.33029,3.13897 -0.0401524,0.849105 0.477105,1.64507 1.21874,2.22728l52.5087 35.5419z"></path>
					  <path className="fil1" d="M1656.32 377.209c-13.5125,0 -15.8378,-9.28937 -15.8378,-26.3789l0 -63.4573 16.7955 0 32.0038 -54.4124 -49.3083 0 0.510171 -50.272 -57.5549 0 0 50.2661 -28.1102 0 0 54.6309 28.1102 0 0 85.501c0,32.5222 26.5242,59.0464 59.0464,59.0476l37.8271 0 0 -54.9237 -23.4821 -0.00118095z"></path>
					  <path className="fil1" d="M1765.45 232.955l0 13.9305c31.085,-26.2691 117.486,-20.7871 117.486,47.2889l0 9.74404 0 128.216 -57.5537 0 0 -104.514c0,-29.2711 -3.98453,-45.1714 -27.1182,-45.1714 -23.1337,0 -27.1182,15.9003 -27.1182,45.1714l0 104.514 -57.5537 0 0.00118095 -144.761 -38.0208 0 32.0038 -54.4124c19.2826,0 38.5829,-0.00590476 57.8738,-0.00590476z"></path>
					  <path className="fil1" d="M1982.79 230.404c61.6292,0 86.8319,45.1679 86.8319,100.865 0,7.99505 -0.524343,15.7704 -1.59783,23.2282l-50.2613 0 -59.2082 0c2.82956,17.4108 10.847,22.7133 30.543,22.7133 14.0793,0 22.6932,-7.88522 27.3615,-19.1775l0.00590476 -0.00354286 47.4755 15.1776c-10.4975,34.7649 -36.0805,58.9272 -81.1503,58.9272 -61.6304,0 -86.8331,-45.1679 -86.8331,-100.865 0,-55.6973 25.2027,-100.865 86.8331,-100.865zm22.4853 75.2503c-3.60309,-12.6043 -10.7667,-19.5566 -22.4853,-19.5566 -11.7198,0 -18.8775,6.95227 -22.4806,19.5566l44.9659 0z"></path>
					  <path className="fil1" d="M2173.35 377.209c-13.5125,0 -15.8378,-9.28937 -15.8378,-26.3789l0 -63.4573 16.7955 0 32.0038 -54.4124 -49.3083 0 0.510171 -50.272 -57.5549 0 0 50.2661 -28.1102 0 0 54.6309 28.1102 0 0 85.501c0,32.5222 26.5242,59.0464 59.0464,59.0476l37.8271 0 0 -54.9237 -23.4821 -0.00118095z"></path>
					 </g>
				</svg>
    )
}
export default Hostnetlogo;