import styled from "styled-components";

export const TextInputStyled=styled.input`
    width: 100%;
    height: 2.3rem;
    border: 1px solid rgb(198, 198, 198);
    &.red{
        border-color: red;
    }
`;

export const Asteristico=styled.span`
    color:red;
    
`;
export const MandatoryText=styled.p`
    color: red;
    font-size: 10pt;
    position: absolute;
`;
export const TextArea=styled.textarea`
    width: 100%;
    
    border: 1px solid rgb(198, 198, 198);
    &.red{
        border-color: red;
    }
`;