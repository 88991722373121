import styled from "styled-components";
import { Row, Col,Container } from "react-bootstrap";
export const MessageBox=styled.div`
    border: 1px solid rgb(198,198,198);
    padding: 20px;
    background-color: rgba(239, 239, 239, 0.3);
`;
export const StyledColumn=styled(Col)`
    display: flex;
    flex-direction: column;
    p{
        position: relative;
    }
`;
