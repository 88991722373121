import { Row, Col, Container } from "react-bootstrap";
import Hostnetlogo from "../../assets/Hostnetlogo";
import {
  ActionButton,
  BoardAvisosDominio,
  PassoBox,
  CategorySelect,
  Header,
  HeaderRow,
  MainContainer,
  NextStep,
  Passo,
  PassoSeta,
  Asteristico,
  FormControlLabelStyled,
  FaQuestionCircle,
  ReCAPTCHAStyled,
} from "./styles";

import {
  CircularProgress,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useContext, useState } from "react";
import TextInputComponent from "../../components/TextInputComponent";
import axios from "axios";
import { MandatoryText } from "../../components/TextInputComponent/styles";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context";

const Home = () => {
  //Form variables
  const [categorySelect, setCategorySelect] = useState<string>("");
  const [contactSelect, setContactSelect] = useState<boolean>(false);
  const [radioSelect, setRadioSelect] = useState<string>("");
  const [TextValue, setTextValue] = useState<string>("");
  const [TextAreaValue, setTextAreaValue] = useState<string>("");
  const [subjectValue, setSubjectValue] = useState<string>("");
  const [emailValue, setEmailValue] = useState<string>("");
  const [emailConfirmationValue, setEmailConfirmationValue] =
    useState<string>("");
  const [name, setName] = useState<string>("");
  const [reCaptcha, setreCaptcha] = useState<string>("");
  //Validation variables
  const [emailValidation, setEmailValidation] = useState<any>();
  const [emailEqual, setEmailEqual] = useState<string>();
  const [reCaptchaValidation, setReCaptchaValidation] =
    useState<boolean>(false);
  const [refresh, setRefresh] = useState<any>(0);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const navigate = useNavigate();
  const context = useContext(UserContext);

  const scrollToBottom = () =>
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const sendData = () => {
    setIsFetching(true);
    if (
      subjectValue &&
      emailValue &&
      emailConfirmationValue &&
      name &&
      TextAreaValue &&
      reCaptcha
    ) {
      setEmailValidation(() => {
        if (!validateEmail(emailValue)) {
          setIsFetching(false);
          return "Por gentileza, coloque um endereço de email válido.";
        }
        return false;
      });

      if (!validateEmail(emailValue)) {
        setIsFetching(false);
        return "Por gentileza, coloque um endereço de email válido.";
      }
      if (emailValue === emailConfirmationValue) {
        const headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
        };

        axios
          .post(
            "https://ctt.apihn.co/api/messages",
            {
              category: categorySelect,
              email: emailValue,
              name: name,
              subject: subjectValue,
              message: TextAreaValue,
            },
            { headers: headers }
          )
          .then(function (response) {
            context.setConfirmation(true);
            console.log(response);
            setIsFetching(false);
            navigate("/confirmacao");
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        setIsFetching(false);
        setEmailEqual("Os emails não são parecidos!");
      }
    } else {
      setIsFetching(false);
      if (reCaptcha) {
        setReCaptchaValidation(false);
      } else {
        setReCaptchaValidation(true);
      }
    }

    setRefresh((prev: any) => prev + 1);
  };
  const verifyCallback = (e: any) => {
    setreCaptcha(e);
    setReCaptchaValidation(false);
  };

  return (
    <>
      <Header>
        <Container className="ps-4 pe-4">
          <HeaderRow>
            <Col
              md={3}
              className="d-flex align-items-center justify-content-center justify-content-md-start"
            >
              <a
                href="https://www.hostnet.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                <Hostnetlogo />
              </a>
            </Col>
            <Col
              md={6}
              className="d-none d-md-flex align-items-center justify-content-center"
            >
              <h1 className="d-none d-lg-flex">
                Hospede seu site ou registre seu domínio
              </h1>
            </Col>
            <Col
              md={3}
              className="d-none d-md-flex flex-column  align-items-end justify-content-center"
            >
              <a
                href="https://www.hostnet.com.br/atendimento-ao-cliente/"
                target="_blank"
                rel="noreferrer"
              >
                <FaQuestionCircle />
              </a>
            </Col>
          </HeaderRow>
        </Container>
      </Header>
      <main>
        <MainContainer className="mt-5 mb-5 ps-md-5 pe-md-5 ps-4 pe-4">
          <Row>
            <PassoBox>
              <Passo>1</Passo>
              <h4>Em que categoria você precisa de ajuda?</h4>
            </PassoBox>
          </Row>
          <Row className="mt-5">
            <BoardAvisosDominio className="p-4 ps-5 pe-5">
              <CategorySelect
                placeholder="Escolha uma categoria"
                value={categorySelect}
                onChange={(e) => setCategorySelect(e.target.value)}
              >
                {!categorySelect && (
                  <option value="">Escolha uma categoria</option>
                )}
                <option value="Parcerias">Parcerias</option>
                <option value="LGPD">
                  LGPD (Lei Geral de Proteção de Dados)
                </option>
                <option value="criacao de site">Criação de Site e Loja</option>
                <option value="seja uma franquia hostnet">
                  Franquia Hostnet
                </option>
                <option value="mautic da hostnet">
                  Automação de Marketing (Mautic)
                </option>
                <option value="tutoriais para wordpress">WordPress</option>
                <option value="tutoriais de cdn">CDN</option>
                <option value="ssl">SSL</option>
                <option value="Planos de Hospedagem">
                  Planos de Hospedagem
                </option>
                <option value="Registro de Domínio .RIO">Registro .RIO</option>
                <option value="Registro Nacional .BR">Registro .BR</option>
                <option value="Registro de Domínios Internacionais .COM e .NET">
                  Registro Internacional (.COM e .NET)
                </option>
                <option value="Cobranças e Pagamentos">
                  Cobranças e Pagamentos
                </option>
                <option value="E-mail">E-mail</option>
                <option value="DNS">DNS</option>
                <option value="FTP">FTP</option>
                <option value="Instalador de Aplicativos">
                  Instalador de Aplicativos
                </option>
                <option value="Tráfego e Espaço em disco">
                  Tráfego e Espaço em Disco
                </option>
                <option value="Banco de dados">Banco de Dados</option>
                <option value="Sua Conta">Outros</option>
              </CategorySelect>
            </BoardAvisosDominio>
          </Row>

          {categorySelect && (
            <>
              <Row className="mt-5 d-flex align-items-center justify-content-center">
                <PassoSeta />
              </Row>
              <Row className="mt-5 ">
                <PassoBox>
                  <Passo>2</Passo>
                  <h4>Sugestões de ajuda</h4>
                </PassoBox>
              </Row>
              <Row>
                <p className="p-0 pt-2">
                  Selecionamos algumas sugestões para você da nossa área de
                  ajuda. Elas resolvem a maioria das dúvidas. Clique nos links
                  abaixo e confira se respondem a sua questão.
                </p>
              </Row>
              <Row>
                <Col sm={6} className="p-0 pe-sm-1">
                  <ActionButton
                    onClick={() => {
                      window.open("https://ajuda.hostnet.com.br/");
                    }}
                  >
                    Quero acessar a área de ajuda
                  </ActionButton>
                </Col>
                <Col sm={6} className="p-0 ps-sm-1 mt-1 mt-sm-0">
                  <ActionButton
                    onClick={() => {
                      setContactSelect(true);
                      setTimeout(() => {
                        scrollToBottom();
                      }, 100);
                    }}
                  >
                    Quero entrar em contato
                  </ActionButton>
                </Col>
              </Row>
            </>
          )}

          {contactSelect && (
            <>
              <Row className="mt-5 d-flex align-items-center justify-content-center">
                <PassoSeta />
              </Row>
              <Row className="mt-5">
                <PassoBox>
                  <Passo>3</Passo>
                  <h4> Gostaria de falar conosco?</h4>
                </PassoBox>
              </Row>
              <Row>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="false"
                    name="radio-buttons-group"
                    onChange={(e) => {
                      setRadioSelect(e.target.value);
                      setTimeout(() => {
                        scrollToBottom();
                      }, 100);
                    }}
                  >
                    <FormControlLabelStyled
                      value=""
                      onClick={() =>
                        window.open("https://painel.hostnet.com.br/helpdesk/")
                      }
                      control={<Radio />}
                      label={
                        <p>
                          <span>
                            Sou cliente e gostaria de falar com alguém através
                            do Helpdesk.
                          </span>
                          <br />
                          Se você já é nosso cliente, tenha atendimento
                          priorizado dos nossos técnicos no Helpdesk Hostnet.
                        </p>
                      }
                    />
                    <FormControlLabelStyled
                      className="mt-3"
                      value="next"
                      control={<Radio />}
                      label={
                        <p>
                          <span>
                            Gostaria de falar com a equipe de atendimento.
                          </span>
                          <br />
                          Preencha nosso formulário de contato ou conheça nossas
                          outras formas de contato.
                        </p>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Row>
            </>
          )}
          {radioSelect && (
            <>
              <Row className="mt-5 d-flex align-items-center justify-content-center ">
                <PassoSeta />
              </Row>
              <Row className="mt-5">
                <PassoBox>
                  <Passo>4</Passo>
                  <h4> Formulário de contato</h4>
                </PassoBox>
              </Row>
              <Row>
                <BoardAvisosDominio className="p-4 ps-5 pe-5">
                  <form
                    action="#"
                    onSubmit={(e) => {
                      e.preventDefault();
                      sendData();
                    }}
                  >
                    <Row>
                      <Col sm={6}>
                        <label htmlFor="categoriaSelect">
                          Categoria <Asteristico>*</Asteristico>
                        </label>
                        <CategorySelect
                          id="categoriaSelect"
                          value={categorySelect}
                          onChange={(e) => setCategorySelect(e.target.value)}
                        >
                          <option value="Parcerias">Parcerias</option>
                          <option value="LGPD">
                            LGPD (Lei Geral de Proteção de Dados)
                          </option>
                          <option value="criacao de site">
                            Criação de Site e Loja
                          </option>
                          <option value="seja uma franquia hostnet">
                            Franquia Hostnet
                          </option>
                          <option value="mautic da hostnet">
                            Automação de Marketing (Mautic)
                          </option>
                          <option value="tutoriais para wordpress">
                            WordPress
                          </option>
                          <option value="tutoriais de cdn">CDN</option>
                          <option value="ssl">SSL</option>
                          <option value="Planos de Hospedagem">
                            Planos de Hospedagem
                          </option>
                          <option value="Registro de Domínio .RIO">
                            Registro .RIO
                          </option>
                          <option value="Registro Nacional .BR">
                            Registro .BR
                          </option>
                          <option value="Registro de Domínios Internacionais .COM e .NET">
                            Registro Internacional (.COM e .NET)
                          </option>
                          <option value="Cobranças e Pagamentos">
                            Cobranças e Pagamentos
                          </option>
                          <option value="E-mail">E-mail</option>
                          <option value="DNS">DNS</option>
                          <option value="FTP">FTP</option>
                          <option value="Instalador de Aplicativos">
                            Instalador de Aplicativos
                          </option>
                          <option value="Tráfego e Espaço em disco">
                            Tráfego e Espaço em Disco
                          </option>
                          <option value="Banco de dados">Banco de Dados</option>
                          <option value="Sua Conta">Outros</option>
                        </CategorySelect>

                        <TextInputComponent
                          maxLength={90}
                          className="mt-3"
                          type="text"
                          label="Assunto"
                          mandatory={true}
                          value={subjectValue}
                          onChange={(e) => setSubjectValue(e.target.value)}
                          refresh={refresh}
                        />
                        <TextInputComponent
                          maxLength={90}
                          className="mt-3"
                          type="email"
                          label="E-mail"
                          mandatory={true}
                          value={emailValue}
                          onChange={(e) => {
                            setEmailValue(e.target.value);
                            setEmailValidation("");
                            setEmailEqual("");
                          }}
                          externalValidationText={emailValidation || emailEqual}
                          refresh={refresh}
                        />
                        <TextInputComponent
                          maxLength={90}
                          className="mt-3"
                          type="email"
                          label="Confirme seu e-mail"
                          mandatory={true}
                          value={emailConfirmationValue}
                          onChange={(e) => {
                            setEmailConfirmationValue(e.target.value);
                            setEmailEqual("");
                          }}
                          externalValidationText={emailEqual}
                          refresh={refresh}
                        />
                      </Col>

                      <Col sm={6}>
                        <TextInputComponent
                          maxLength={90}
                          className="mt-3 mt-sm-0"
                          type="text"
                          label="Nome"
                          mandatory={true}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          refresh={refresh}
                        />
                        <TextInputComponent
                          maxLength={90}
                          className="mt-3"
                          type="text"
                          label="Domínio"
                          mandatory={false}
                          value={TextValue}
                          onChange={(e) => setTextValue(e.target.value)}
                        />
                        <TextInputComponent
                          maxLength={1000}
                          className="mt-3"
                          type="textarea"
                          label="Mensagem"
                          mandatory={true}
                          value={TextAreaValue}
                          onChange={(e) => setTextAreaValue(e.target.value)}
                          refresh={refresh}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ReCAPTCHAStyled
                          sitekey="6Lfh5goTAAAAAONLF5ZWoVCLi5guK0zHIpvWy4E1"
                          onChange={verifyCallback}
                          className="mt-3"
                        />
                        {reCaptchaValidation && (
                          <MandatoryText>Verifique o ReCAPTCHA</MandatoryText>
                        )}
                      </Col>
                      <Col>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <NextStep
                            type="submit"
                            disabled={isFetching}
                            className="mt-3"
                          >
                            {isFetching ? (
                              <CircularProgress
                                style={{ width: "30px", height: "30px" }}
                              />
                            ) : (
                              "Enviar mensagem"
                            )}
                          </NextStep>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </BoardAvisosDominio>
              </Row>
            </>
          )}
        </MainContainer>
      </main>
    </>
  );
};
export default Home;
