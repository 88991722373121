import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
    defaultOptions:{
        queries:{
            refetchOnWindowFocus:false,
            cacheTime: 1000 * 60 * 60 * 24,
        }
    }
    
})